<template>
  <div class="enterpriseInfo">
    <div class="search">
      <el-button @click="addClick" class="butColor" type="primary" size="small">新建任务</el-button>
      <el-form ref="searchForm" class="searchForm" :model="searchForm" label-width="100px">
        <el-form-item label="关键字">
          <el-input v-model="searchForm.name" placeholder="请输入任务名称"></el-input>
          <el-button @click="searchBut(searchForm)" class="searchBut" icon="el-icon-search"></el-button>
        </el-form-item>
        <!-- <el-form-item label="任务下发时间">
          <el-date-picker @change="timeChange" value-format="yyyy-MM-dd" v-model="searchForm.time" type="date" placeholder="选择日期"> </el-date-picker>
        </el-form-item> -->
      </el-form>
    </div>
    <div class="table_box">
      <el-table class="table" :data="tableData" style="width: 100%">
        <el-table-column label="序号" :index="indexMethod" type="index" show-overflow-tooltip
                         width="50"></el-table-column>
        <el-table-column prop="taskName" label="任务名称" align="center"></el-table-column>
        <el-table-column prop="zxr" label="任务执行人" show-overflow-tooltip align="center">
          <template slot-scope="scope">
            <span>{{ getName1(scope.row.zxr) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="cropName" label="名称" align="center"></el-table-column>
        <el-table-column prop="inputId" show-overflow-tooltip label="投入品" align="center">
          <template slot-scope="scope">
            <span>{{ getInputName(scope.row.inputId) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="sysl" label="使用数量" align="center"></el-table-column>
        <el-table-column fixed="right" label="操作" width="200" align="center">
          <template slot-scope="scope">
            <div slot="avatar" class="operation">
              <span @click="eait(scope.row)">编辑</span>
              <span @click="delect(scope.row)">删除</span>
              <!--              <span @click="receipt(scope.row)">回执</span>-->
            </div>
          </template>
        </el-table-column>
      </el-table>
      <page @changePage="eventPage" @changeSize="eventPage" class="pageStyle" :pagination="pagination"/>
      <!-- 添加 -->
      <el-dialog destroy-on-close :title="titles" :visible.sync="dialogVisible" width="30%">
        <el-form ref="addForm" :rules="rules" :model="addForm" label-width="130px">
          <el-form-item label="任务名称" prop="taskName">
            <el-input :disabled="infoState" v-model="addForm.taskName" placeholder="请输入任务名称"></el-input>
          </el-form-item>
          <el-form-item label="任务详情" prop="taskDesc">
            <el-input :disabled="infoState" type="textarea" v-model="addForm.taskDesc"></el-input>
          </el-form-item>
          <el-form-item label="名称" prop="cropId">
            <el-select :disabled="infoState" v-model="addForm.cropId" placeholder="请选择名名称" @change="batchChange">
              <el-option v-for="self in batchList" :key="self.id" :label="self.cropName" :value="self.id"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="执行人" prop="zxr">
            <el-select
                :disabled="infoState"
                v-model="addForm.zxr"
                placeholder="请选择执行人"
                @change="changeZxr"
            >
              <el-option
                  v-for="item in nameList"
                  :key="item.id"
                  :label="item.realName"
                  :value="item.userName"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="执行人电话" prop="zxrdh">
            <el-input :disabled="infoState" v-model="addForm.zxrdh" placeholder="请输入执行人电话"></el-input>
          </el-form-item>
          <el-form-item label="投入品" prop="inputId">
            <el-select :disabled="infoState" v-model="addForm.inputId" placeholder="请选择投入品" @change="inputChange">
              <el-option v-for="item in inputList" :key="item.inputId"
                         :label="item.inputName+' / '+item.quantity+' / '+item.unit" :value="item.inputId"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="使用数量" prop="sysl">
            <el-input type="number" :disabled="infoState" v-model="addForm.sysl" placeholder="请输入使用数量"></el-input>
          </el-form-item>
          <el-form-item label="使用目的" prop="sysj">
            <el-input :disabled="infoState" v-model="addForm.symd" placeholder="请输入使用目的"></el-input>
          </el-form-item>
          <el-form-item label="使用时间" prop="sysj">
            <el-date-picker :disabled="infoState" v-model="addForm.sysj" value-format="yyyy-MM-dd" type="datetime"
                            placeholder="选择日期"></el-date-picker>
          </el-form-item>
          <el-form-item label="任务图片">
            <FileUpload ref="taskImg" :fileRealTimeUp="false" :fileListModify="fileListModify" upFileKey="taskImg"
                        valueType="string" :fileType='["jpg", "jpeg", "png"]' :limit="5"
                        @getFileListAndForm="getFileListAndForm"/>
          </el-form-item>
        </el-form>
        <span v-if="!infoState" slot="footer" class="dialog-footer">
          <el-button size="small" @click="dialogVisible = false">取 消</el-button>
          <el-button size="small" class="butColor" type="primary" @click="saveFrom(addForm,'addForm')">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 回执 -->
      <el-dialog :title="titles" :visible.sync="receiptBox" width="30%" class="receip">
        <el-form ref="receipForm" :rules="rules2" :model="receipForm" label-width="120px">

          <el-form-item label="回执详情" prop="receiptDetail">
            <!--            <el-input type="textarea" v-model="receipForm.receiptDetail"></el-input>-->
            <textarea v-model="receipForm.receiptDetail" class="el-textarea__inner"></textarea>
          </el-form-item>
          <el-form-item label="完成时间" prop="taskEndTime">
            <el-date-picker :disabled="infoState" v-model="receipForm.taskEndTime" value-format="yyyy-MM-dd HH:mm:ss"
                            @input="$forceUpdate()" type="datetime" placeholder="选择日期"></el-date-picker>
          </el-form-item>

          <el-form-item
              class="isUpload"
              label="回执图片"
              prop="receiptImgs"
          >
            <el-upload
                class="avatar-uploader"
                action="/imgUpdata"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
                :headers="{token}"
            >

              <img
                  v-if="receipForm.receiptImgs"
                  :src="receipForm.receiptImgs"
                  class="businessImg avatar"
                  style="width: 10vw;"
              />
              <i class="el-icon-plus" v-else></i>
            </el-upload>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button size="small" @click="receiptBox = false">取 消</el-button>
          <el-button v-show="receipLool == false" size="small" class="butColor" type="primary"
                     @click="reportFrom(receipForm, 'receipForm')">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import page from '@/components/page.vue';
import FileUpload from "@/components/lxw/FileUpload/index";
import {farmingTaskRequire, agriculturalReceipt} from '@/utils/required.js';

export default {
  components: {
    page, FileUpload
  },

  data() {
    return {
      fileListModify: [],
      filesList: [],
      tableData: [],
      addForm: {},
      searchForm: {},
      processList: [],
      dialogVisible: false,
      receiptBox: false,
      titles: '新建',
      pagination: {
        totalRow: 0,
        page: 1,
        size: 8,
      },
      rules: farmingTaskRequire,
      rules2: agriculturalReceipt,
      enterpriseList: [],
      loading: true,
      timeModel: true,
      infoState: false,
      searchListInfo: {
        name: '员工姓名',
        type: '岗位',
        time: '时间',
      },
      batchList: [],
      nameList: [],
      staffList: [],
      receipForm: {},
      fileList: [],
      lifeCysle: '',
      inputValue: '',
      inputList: [],
      reportId: 0,
      token: '',
      receipLool: false,
    };
  },
  watch: {
  },
  mounted() {
    this.token = JSON.parse(window.localStorage.getItem('token'));
    this.getUserList();
    this.requestLsit();
    this.requestBatch();
    this.getInputData();
    // this.requestStaff();
  },
  methods: {
    // 序号
    indexMethod(index) {
      index = index + 1 + (this.pagination.page - 1) * this.pagination.size;
      return index;
    },
    getFileListAndForm(obj) {//检测报告
      if (obj && obj.fileList) {
        const {fileList} = obj;
        let list = [];
        fileList.forEach((item) => {
          if (
              item.response &&
              item.response.data
          ) {
            list.push(item.response.data);
          }
        });
        this.filesList = list;
      }
    },
    // 新建
    addClick() {
      this.infoState = false;
      this.titles = '新建';
      this.$nextTick(() => {
        this.dialogVisible = true;
        this.$refs['addForm'].resetFields();
        this.addForm = {};
      });
    },

    // 获取投入品信息
    getInputData() {
      this.$get('/inputPurchase/pageRepertory', {
        page: 1,
        size: 10000,
      }).then((res) => {
        if (res.data.state == 'success') {
          let {
            data
          } = res.data;
          this.inputList = data;
          console.log("投入品列表：", this.inputList);
        }
      });
    },
    //获取投入品名称
    getInputName(inputId) {
      let name = "";
      this.inputList.forEach(item => {

        if (item.inputId == inputId) {
          name = item.inputName;
        }
      })
      return name;

    },
    // 获取用户列表
    getUserList() {
      this.$get("/userManage/getUserList", {
        page: 1,
        limit: 100,
      }).then((res) => {
        if (res.data.state == "success") {
          this.nameList = res.data.datas;
        }
      });
    },
    getName1(val) {
      for (let a = 0; a < this.nameList.length; a++) {
        if (this.nameList[a].userName == val) {
          return this.nameList[a].realName;

        }
      }
    },
    inputChange(val) {
      let inputN = this.inputList.find((v) => v.id == val).inputName;
      this.addForm.inputName = inputN;
      let inputNum = this.inputList.find((v) => v.id == val).repertoryNumber;
      this.addForm.repertoryNumber = inputNum;
      let inputUnit = this.inputList.find((v) => v.id == val).unit;
      this.addForm.unit = inputUnit;
    },
    changeZxr(val) {
      this.nameList.forEach(item => {
        if (item.userName == val) {
          this.addForm.zxrdh = item.tel;
        }
      })

    },

    requestBatch() {
      //   this.$get('/plantingBatch/getPlantingBatchList', { page: 1, size: 10000 }).then((res) => {
      this.$get('/cropManagement/getCropManagements', {page: 1, size: 10000}).then((res) => {
        if (res.data.state == 'success') {
          this.batchList = res.data.datas;
        }
      });
    },
    timeChange(val) {
      //console.log(val);
      this.requestLsit('', val);
    },
    // 名批次切换
    batchChange(val) {
      let name = this.batchList.find((v) => v.id == val).batchNumber;
      this.addForm.plantingNumber = name;
      let lifeCycleId = this.batchList.find((v) => v.id == val).lifeCycleId;
      this.addForm.lifeCyclesId = lifeCycleId;
      let crop = this.batchList.find((v) => v.id == val).cropId;
      this.processInfo(crop);
    },
    // 生长周期切换
    deviceChange(val) {
      //console.log(val);
      this.addForm.lifeCyclesModelInfoId = val;
    },
    /**
     * @description: 生长周期流程信息
     */
    processInfo(plantingBatchId) {
      this.$get('/lifeCycles/getLifeCycleList', {plantingBatchId}).then((res) => {
        //console.log('流程信息', res);
        if (res.data.state == 'success') {
          //console.log(res);
          this.addForm.lifeCyclesModelId = res.data.datas[0].lifeCyclesModelList[0].id;
          this.processList = res.data.datas[0].lifeCyclesModelList[0].lifeCyclesModelInfoList;
        }
      });
    },
    processInfo2(plantingBatchId) {
      this.$get('/lifeCycles/getLifeCycleList').then((res) => {
        //console.log('流程信息', res);
        if (res.data.state == 'success') {
          let datas = res.data.datas;
          for (let i = 0; i < datas.length; i++) {
            if (datas[i].id == plantingBatchId) {
              this.processList = datas[i].lifeCyclesModelList[0].lifeCyclesModelInfoList;
            }
          }
          // this.processList = res.data.datas[0].lifeCyclesModelList[0].lifeCyclesModelInfoList;
          // this.addForm.lifeCyclesId = plantingBatchId;
        }
      });
    },
    currentTime() {
      let data = new Date();
      let month = data.getMonth() < 9 ? '0' + (data.getMonth() + 1) : data.getMonth() + 1;
      let date = data.getDate() <= 9 ? '0' + data.getDate() : data.getDate();
      return data.getFullYear() + '-' + month + '-' + date;
    },
    /**
     * @description: loadingg 关闭
     * @param {*}
     */

    exitLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
    /**
     * @description:  请求数据/搜索
     * @param {*} name 关键字搜索
     * @param {*} enterpriseType 岗位搜索
     */
    // 获取数据列表
    requestLsit(name, time) {
      this.$get('/pro10/nsrw/pageTask', {
        page: this.pagination.page,
        size: this.pagination.size,
        taskName: name || '',
        time: time || ''
      })
          .then((res) => {
            if (res.data.state == 'success') {
              let tableList = JSON.parse(JSON.stringify(res.data.data));
              this.pagination.totalRow = res.data.size;
              this.tableData = tableList;

              //console.log(tableList);
              this.exitLoading();
            }
          })
          .catch((err) => {
            this.exitLoading();
            throw err;
          });
    },
    /**
     * @description:  保存
     * @param {*} data 数据
     * @param {*} dom 元素
     */
    saveFrom(data, dom) {
      //console.log(data, dom);
      let url = '/pro10/nsrw/saveTask';
      if (this.titles == '新建') {
        // url = '/farmingTask/addOrUpdateFarmingTask';
        data.state = 0;
        delete data.id;
      }
      data.taskImg = (this.filesList && this.filesList.length > 0) ? JSON.stringify(this.filesList) : null,
          this.$refs[dom].validate((valid) => {
            if (valid) {
              delete data.growthCycle;
              this.$postJSON(url, data).then((res) => {
                // //console.log(res, '接口数据');
                if (res.data.state == 'success') {
                  this.dialogVisible = false;
                  this.$message.success(res.data.msg);
                  this.requestLsit();
                }
              });
            }
          });
    },
    /**
     * @description: 回执
     * @param {*}
     */

    reportFrom(data, dom) {
      //if (this.fileList.length == 0) this.$message.info('请上传图片');
      // this.fileList.forEach((v) => (v.imgUrls = 'https://public.half-half.cn/' + v.response.data.fileName));
      // data.receiptImg = JSON.stringify(this.fileList);
      // data.state = 2;
      data.id = this.reportId;
      this.$post('/farmTask/addReceiptInfo', data).then((res) => {
        if (res.data.state == 'success') {
          this.receiptBox = false;
          this.$message.success(res.data.msg);
          this.requestLsit();
        }
      });
    },
    /**
     * @description:  修改
     * @param {*} data
     */
    eait(data) {
      this.titles = '修改';
      this.dialogVisible = true;
      this.addForm = data
      this.infoState = false;
      let fj = data.taskImg ? JSON.parse(data.taskImg) : [];
      let url = "http://public.half-half.cn/" + fj[0]
      this.fileListModify = [
        {name: fj[0], url: url},
      ];


    },
    // 详情
    infoFarming(data) {
      this.$nextTick(() => {
        this.titles = '详情';
        this.dialogVisible = true;
        this.infoState = true;
        this.processInfo2(data.batchId);
        this.$nextTick(() => {
          this.addForm = JSON.parse(JSON.stringify(data));
          this.addForm.batchId = Number(this.addForm.batchId);
        });
      });
    },
    /**
     * @description: 删除
     * @param {*} data
     */
    delect(data) {
      this.$confirm(`此操作将永久删除 ${data.name} 信息, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
          .then(() => {
            this.$post(`/pro10/nsrw/deleteTask/${data.id}`).then((res) => {
              if (res.data.state == 'success') {
                this.requestLsit();
                this.loading = true;
                this.$message.success(res.data.msg);
              } else {
                this.$message.error(res.data.msg);
              }
            });
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
            });
          });
    },
    /**
     * @description:  回执
     * @param {*}
     */

    receipt(data) {
      //console.log(data);
      this.receiptBox = true;
      this.reportId = data.id;
      this.receipLool = false;
      this.infoState = false;
      this.$nextTick(() => {
        this.$refs['receipForm'].resetFields();
        //   // this.receipForm = JSON.parse(JSON.stringify(data));
        //   // this.receipForm.batchId = Number(this.receipForm.batchId);
        //   // this.receipForm.types = Number(this.receipForm.types);
      });
    },
    // 查看回执
    receiptLook(data) {
      //console.log(data);
      // this.receipForm = {};
      this.receipLool = true;
      this.receiptBox = true;
      this.$nextTick(() => {
        this.receipForm = data;
        this.receipForm.receiptDetail = data.receiptDetail;
        this.receipForm.receiptImgs = data.receiptImgs;
        this.receipForm.taskEndTime = data.taskEndTime;
        // this.receipForm.batchId = Number(this.receipForm.batchId);
        // this.receipForm.types = Number(this.receipForm.types);
      });
    },
    /**
     * @description: 接受任务
     */

    acceptBut(data) {
      this.$confirm('确定接受此任务吗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
          .then(() => {
            data.state = 1;
            this.$get('/farmingTask/addOrUpdateFarmingTask', data).then((res) => {
              if (res.data.state == 'success') {
                this.dialogVisible = false;
                this.$message.success('接受任务成功!');
                this.requestLsit();
              }
            });
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消任务接受',
            });
          });
    },
    handleChange(file, fileList) {
      this.fileList = fileList.slice(-1);
    },
    handleExceed() {
      this.$message.info('只能上传三个文件');
    },
    beforeAvatarUpload(val) {
      const fileLimitSize = 5;// 几兆限制
      const isJPG = val.type === "image/jpeg";
      const isJPGs = val.type === "image/png";
      const fileLimit = val.size / 1024 / 1024 < fileLimitSize;
      if (!isJPG && !isJPGs) {
        this.$message.info("上传图片格式只能为 JPG 或 PNG 格式！");
        this.fileList2 = [];
        return false
      }
      if (!fileLimit) {
        this.$message.info(`上传图片大小不能超过${fileLimitSize}MB!`);
        this.fileList2 = [];
        return false
      }

      return true;
    },
    handleAvatarSuccess(res, file) {
      //console.log(res);
      //console.log(file.name);
      this.receipForm.receiptImgs = 'https://public.half-half.cn/' + res.data;
      this.receipForm = JSON.parse(JSON.stringify(this.receipForm))
      //   this.receipForm.receiptImgName = file.name;
      this.uploadState = 1;
    },
    onRemove(file, fileList) {
      this.addFormModel.url = '';
    },
    //
    searchBut(data) {
      this.requestLsit(data.name);
    },
    searchFormType(val) {
      this.requestLsit(val);
    },
    eventPage() {
      this.requestLsit();
    },
  },
};
</script>
<style scoped lang="less">
@import "~@/style/colorPublic";

.butColor {
  // background: @but_color;
  background: #409eff;
}

.enterpriseInfo {
  background: #fff;
  padding: 2vh;
  box-sizing: border-box;
}

.operation {
  display: flex;
  justify-content: center;

  span {
    cursor: pointer;
    margin-right: 1vh;
  }
}

.enterpriseInfo /deep/ .el-dialog__title {
  font-size: 1.5vh;
}

.table {
  height: 75vh;
  overflow-y: auto;
}

.pageStyle {
  text-align: @pageAlign;
  margin-top: 1vh;
}

.search {
  display: flex;
  align-items: center;
  margin-bottom: 1vh;
}

.searchForm {
  display: flex;
  position: relative;
}

.searchForm /deep/ .el-form-item {
  margin-bottom: 0;
}

.searchBut {
  position: absolute;
  right: 0;
  top: 4px;
}

.searchForm /deep/ .el-input__inner {
  // height: 4vh !important;
  height: 30px !important;
  line-height: 4vh !important;
}

.searchForm /deep/ .el-button--default {
  // height: 4vh !important;
  height: 30px !important;
  padding: 9px 19px;
}

.accept {
  cursor: pointer;
  background: #3366cc;
  color: #fff;
  border-radius: 5px;
  padding: 0.5vh;
  box-sizing: border-box;
}

// .search{
//     border: 1px solid #ccc;
//     padding: 1vh;
//     box-sizing: border-box;
// }
.search {
  display: flex;
  align-items: center;
  margin-bottom: 1vh;
  // border: 1px solid #ccc;
  padding: 1vh;
  box-sizing: border-box;
}

.searchForm {
  display: flex;
  position: relative;
}

.searchForm /deep/ .el-form-item {
  margin-bottom: 0;
}

.searchBut {
  position: absolute;
  right: 0;
  top: 6px;
}

/* .searchForm /deep/ .el-input__inner {
  height: 5vh !important;
  line-height: 5vh !important;
}
.searchForm /deep/ .el-button--default {
  height: 5vh !important;
  padding: 9px 19px;
} */

.receip /deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 10vw;
  height: 10vw;
  line-height: 10vw;
  font-size: 1vw;
}

.receip /deep/ .avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.receip /deep/ .avatar-uploader-icon {
  font-size: 5vw;
  color: #8c939d;
  width: 10vw;
  height: 10vw;
  line-height: 10vw;
  text-align: center;
}

.receip /deep/ .avatar {
  width: 10vw;
  height: 10vw;
  display: block;
}

@media screen and (max-width: 1500px) {
  .searchForm /deep/ .el-input__inner {
    height: 5vh !important;
    line-height: 5vh !important;
  }

  .searchForm /deep/ .el-button--default {
    height: 5vh !important;
    padding: 9px 19px;
  }

  .searchBut {
    position: absolute;
    right: 0;
    top: 4px;
  }

  .table {
    height: 70vh;
    overflow-y: auto;
  }
}
</style>
