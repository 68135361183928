
/**
 * @description: 手机号
 */
var checkAge = (rule, value, callback) => {
  const phoneReg = /^1[3|4|5|7|8][0-9]{9}$/;
  if (!value) {
    return callback(new Error("手机号不可为空"));
  }
  if (!phoneReg.test(value)) {
    return callback(new Error("手机号不正确！"));
  }
  callback();
};
/**
 * @description: 邮箱
 */
var checkAge2 = (rule, value, callback) => {
  const emailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
  if (!value) {
    return callback(new Error("邮箱不可为空"));
  }
  if (!emailReg.test(value)) {
    return callback(new Error("邮箱格式不正确！"));
  }
  callback();
};
/**
 * @description: 纯数字
 */
var regNumber = (rule, value, callback) => {
  const emailReg = /^\d+$|^\d+[.]?\d+$/;
  if (!value) {
    return callback(new Error("不可为空"));
  }
  if (!emailReg.test(value)) {
    return callback(new Error("只支持数字！"));
  }
  callback();
};
// cannotEmpty
const cannotEmpty = (message) => {
  return { required: true, message: message, trigger: ["change", "blur"] };
};
const inputRequire = (message) => {
  return [
    { required: true, message: message, trigger: ["blur", "change"] },
    {
      min: 1,
      max: 127,
      message: "长度在 1到 127 个字符",
      trigger: ["blur", "change"],
    },
  ];
};
/**
 * @description: 企业信息
 */
const required = {
  name: inputRequire("请输入企业名称"),
  legalRepresentative: inputRequire("请输入法人代表"),
  enterpriseType: [cannotEmpty("请选择企业类型")],
  industryType: [cannotEmpty("请选择产业类型")],
  address: inputRequire("请输入产业地址"),
  introduction: inputRequire("请输入产业简介"),
  phoneNum: [
    { required: true, message: "手机号不能为空" },
    { validator: checkAge, trigger: ["blur", "change"] },
  ],
  mailBox: [
    { required: true, message: "邮箱不能为空" },
    { validator: checkAge2, trigger: ["blur", "change"] },
  ],
};
/**
 * @description: 员工
 */
const staff = {
  name: inputRequire("请输入员工姓名"),
  post: [cannotEmpty("请选择员工岗位")],
  tel: [
    { required: true, message: "手机号不能为空" },
    { validator: checkAge, trigger: "blur" },
  ],
  dateOfBirth: [cannotEmpty("请选择生日")],
};

/**
 * @description: 农事任务
 */
const farmingTaskRequire = {
  taskName: inputRequire("请输入任务名称"),
  taskType: [cannotEmpty("请选择任务类型")],
  plantingId: [cannotEmpty("请选择种植批次")],
  lifeCyclesModelInfoId: [cannotEmpty("请选择生长周期")],
  taskHolder: inputRequire("请输入任务执行人"),
  //taskHolderTel: inputRequire("请输入执行人电话"),
  issuer: [cannotEmpty("请选择任务下发人")],
  completionTime: [cannotEmpty("请选择任务下发时间")],
  isUseInput: [cannotEmpty("请选择是否使用投入品")],
  taskDetail: inputRequire("请输入任务详情"),
  growthCycle: {
    required: true,
    message: "请选择生长周期流程",
    trigger: ["blur"],
  },
};

/**
 * @description: 回执
 */
const agriculturalReceipt = {
  receiptDetail: inputRequire("请输入回执详情"),
  taskEndTime: inputRequire("请选择完成时间"),
};
/**
 * @description: 种植批次
 */
const plantingBatch = {
  name: inputRequire("请输入员工姓名"),
  post: [cannotEmpty("请选择员工岗位")],
  tel: [
    { required: true, message: "手机号不能为空" },
    { validator: checkAge, trigger: "blur" },
  ],
  dateOfBirth: [cannotEmpty("请选择生日")],
};
/**
 * @description: 购买记录
 */
const purchaseRecord = {
  plantBatch: [cannotEmpty("请选择种植批次")],
  name: inputRequire("请输入作物名称"),
  recoveryBatch: inputRequire("请输入采收批次"),
  plotName: inputRequire("请输入采购地块"),
  recoveryPrincipal: [cannotEmpty("请选择采收负责人")],
  processState: [cannotEmpty("请选择是否加工")],
  recoveryTime: [cannotEmpty("请选择采收日期")],
  plantBatchState: [cannotEmpty("请选择种植批次采收结束")],
  harvestYield: inputRequire("请输入收获量"),
};
/**
 * @description: 农事任务
 */
const usageRecord = {
  nameId: [cannotEmpty("请选择投入品名称")],
  userNameId: [cannotEmpty("请选择使用者名称")],
  userTel: [
    { required: true, validator: checkAge, trigger: ["blur", "change"] },
  ],
  batchNumberId: [cannotEmpty("请选择批次号")],
  useQuantity: [
    { required: true, validator: regNumber, trigger: ["blur", "change"] },
  ],
  usePurpose: inputRequire("请输入使用目的"),
  useTime: [cannotEmpty("请选择使用时间")],
  farmingTaskId: [cannotEmpty("请选择任务名称")],
};
const massif = {
  name: inputRequire("请输入基地名称"),
  enterpriseId: [cannotEmpty("请选择企业")],
  type: [cannotEmpty("请选择产业类型")],
  enterpriseName: inputRequire("请输入面积信息"),
  address: inputRequire("请输入基地地址"),
  linkMan: inputRequire("请输入联系人"),
  areaInfo: inputRequire("请输入基地信息"),
  phoneNum: [
    { required: true, message: "联系电话不能为空" },
    { validator: checkAge, trigger: ["blur", "change"] },
  ],
  email: [
    { required: true, message: "邮箱不能为空" },
    { validator: checkAge2, trigger: ["blur", "change"] },
  ],
};
// 批次
const batch = {
  productName: [cannotEmpty("请选择作物名称")],
  plantType: inputRequire("请输入作物类别"),
  greenhouseName: [
    { required: true, message: "请输入地块名称", trigger: ["blur"] },
    {min: 1,
      max: 127,
      message: "长度在 1到 127 个字符",
      trigger: ["blur", "change"],
    },
  ],
  batchNumber: inputRequire("请输入种植批次"),
  greenhousesName: inputRequire("请输入大棚名称"),
  contactPerson: inputRequire("请输入联系人"),
  tel: [
    { required: true, message: "联系电话不能为空" },
    { validator: checkAge, trigger: ["blur", "change"] },
  ],
  plantingArea: inputRequire("请输入面积信息"),
  plantingTime: [cannotEmpty("请选择种植时间")],
  camera: [cannotEmpty("请选择摄像头")],
  meteorologicalEquipment: [cannotEmpty("请选择气象设备")],

  phoneNum: [
    { required: true, message: "联系电话不能为空" },
    { validator: checkAge, trigger: ["blur", "change"] },
  ],
  email: [
    { required: true, message: "邮箱不能为空" },
    { validator: checkAge2, trigger: ["blur", "change"] },
  ],
  plantName: inputRequire("请输入作物名称"),
};
/**
 * @description: 购买记录
 */
const newRecord = {
  inputName: inputRequire("请输入投入品名称"),
  purchaseNumber: [
    { required: true, validator: regNumber, trigger: ["blur", "change"] },
  ],
  unit: inputRequire('请输入规格,例"100/kg"'),
  purchasingAgent: [cannotEmpty("请输入采购人")],
  purchaseTime: [cannotEmpty("请选择采购时间")],
  dealer: inputRequire("请输入经销商"),
  //dealerAgent: inputRequire("请输入经销商负责人"),
  /*dealerTel: [
    { required: true, message: "手机号不能为空" },
    { validator: checkAge, trigger: "blur" },
  ],*/
  //producer: inputRequire("请输入生产商"),
  //remarks: inputRequire("请输入备注"),
  inputImg:inputRequire("请选择投入品照片"),
  examiningReportImg:inputRequire("请选择质量监测报告照片"),
};

export {
  required,
  staff,
  checkAge,
  purchaseRecord,
  usageRecord,
  massif,
  batch,
  farmingTaskRequire,
  agriculturalReceipt,
  newRecord,
};
