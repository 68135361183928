<template>
  <el-pagination
    class="pagingBox"
    @size-change="sizeChange"
    @current-change="currentChange"
    :total="pagination.totalRow"
    :current-page="pagination.page"
    :page-size="pagination.size"
    :page-sizes="[10, 20, 30, 40, 50]"
    layout="total, sizes, prev, pager, next, jumper"
  >
  </el-pagination>
</template>
<script>
export default {
  props: {
    pagination: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  methods: {
    sizeChange(pageSize) {
      //console.log(pageSize);
      this.pagination.page = 1;
      this.pagination.size = pageSize;
      this.$emit("changeSize");
    },
    currentChange(pageIndex) {
      this.pagination.page = pageIndex;
      this.$emit("changePage");
    },
  },
};
</script>
<style lang="less" scoped>
.pagingBox {
  position: fixed;
  right: 5vh;
}
</style>
